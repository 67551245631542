import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getApiEndpoint } from '../utils/apiUtils';

export const ftcUpload = createAsyncThunk('ftc/upload', async ({ file }) => {
    return axios
        .post(getApiEndpoint('file', `ftc`), file)
        .then(res => res.data);
});

export const resetFtcUploadInfo = createAction('ftc/resetFtcUploadInfo');

const ftcSlice = createSlice({
    name: 'ftc',
    initialState: {
        loading: false,
        ftc: [],
        error: '',
        isError: false
    },

    extraReducers: builder => {
        // phones get

        builder.addCase(ftcUpload.pending, state => {
            state.loading = true;
            state.isError = false;
        });

        builder.addCase(ftcUpload.fulfilled, (state, action) => {
            state.loading = false;
            state.ftc = action.payload;
            state.error = '';
            state.isError = false;
        });

        builder.addCase(ftcUpload.rejected, (state, action) => {
            state.loading = false;
            state.ftc = [];
            state.error = action.error.message;
            state.isError = true;
        });

        builder.addCase(resetFtcUploadInfo, state => {
			state.loading = false;
            state.isError = false;
        });
    }
});

export default ftcSlice.reducer;
