import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { IoMdCloseCircle } from "../../icons";
import './CheckResultsDetails.css';
import {
    approve,
    approveAll,
    deny,
    denyAll,
    campaignDetails,
    spamResult,
    resetCampaignCheckState
} from '../../slice/campaignCheckSlice';
import Loader from "../Loader";

const CheckResultsDetails = ({ isCampaignCheck, campaign, onCloseModal }) => {
    const dispatch = useDispatch();
    const results = useSelector(state => state.campaignCheck.results);
    const spamResults = useSelector(state => state.campaignCheck.spamResult);
    const loading = useSelector(state => state.campaignCheck.loading);

    useEffect(() => {
        return () => {
            dispatch(resetCampaignCheckState());
        };
    }, [dispatch]);

    const handleApprove = async (tfn) => {
        await dispatch(approve({ campaignId: campaign.id, tfn: tfn }));
        refreshData();
    };

    const handleApproveAll = async () => {
        await dispatch(approveAll({ campaignId: campaign.id }));
        refreshData();
    };

    const handleDeny = async (tfn) => {
        await dispatch(deny({ campaignId: campaign.id, tfn: tfn }));
        refreshData();
    };

    const handleDenyAll = async () => {
        await dispatch(denyAll({ campaignId: campaign.id }));
        refreshData();
    };

    const refreshData = async () => {
        await dispatch(spamResult({ campaignId: campaign.id }));
        await dispatch(campaignDetails({ campaignId: campaign.id }));
    }

    return (
        <div className="check-results-modal">
            <div className="check-results-content">
                <div className="check-results-header">
                    <div className="check-results-header-title">
                        {isCampaignCheck ? (
                            <span className="title">Campaign Check Details for {campaign.name}</span>
                        ) : (
                            <span className="title">Spot Check Details for {campaign.name}</span>
                        )}
                    </div>
                    <button className="check-results-close-button" onClick={() => onCloseModal()}>
                        <IoMdCloseCircle />
                    </button>
                </div>
                {loading ? (
                    <Loader />
                    ) : (
                <div className="details-summary">
                    <span className="details-name">{campaign.name}</span>
                    <span className="details-total">{spamResults?.totalSpam}/{spamResults?.total}</span>
                        {results.length > 0 && (
                            <div>
                                <button className="approve-all-button-modal"
                                        onClick={() => handleApproveAll()}
                                        style={{ cursor: "pointer" }}>Approve All
                                </button>
                                <span>/</span>
                                <button className="deny-all-button-modal"
                                        onClick={() => handleDenyAll()}
                                        style={{ cursor: "pointer" }}>Deny All
                                </button>
                            </div>
                        )}
                </div>)}
                {!loading && results.length > 0 ? (
                    <div className="table">
                        <table className="dash-table">
                            <thead>
                                <tr>
                                    <th>TFN</th>
                                    <th>AT&T</th>
                                    <th>T-Mobile</th>
                                    <th>Verizon</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {results.map((result, index) => (
                                    <tr key={index}>
                                     <td style={{ width: '15%' }}>{result.tfn}</td>
                                        <td style={{ width: '25%' }}>
                                            <div className="badge-container">
                                                <strong>{result.attName} </strong>
                                                {result.attName === null ? (
                                                <span className='fail-badge'>FAIL</span>
                                                ) : result.isSpamAtt === true ? (
                                                <span className='spam-badge'>SPAM</span>
                                                ) : (
                                                <span className='clean-badge'>CLEAN</span>
                                                )}
                                            </div>
                                        </td>
                                        <td style={{ width: '25%' }}>
                                            <div className="badge-container">
                                                <strong>{result.tMobileName} </strong>
                                                {result.tMobileName === null ? (
                                                <span className='fail-badge'>FAIL</span>
                                                ) : result.isSpamTMobile === true ? (
                                                <span className='spam-badge'>SPAM</span>
                                                ) : (
                                                <span className='clean-badge'>CLEAN</span>
                                                )}
                                            </div>
                                        </td>
                                        <td style={{ width: '25%' }}>
                                            <div className="badge-container">
                                                <strong>{result.verizonName} </strong>
                                                {result.verizonName === null ? (
                                                <span className='fail-badge'>FAIL</span>
                                                ) : result.isSpamVerizon === true ? (
                                                <span className='spam-badge'>SPAM</span>
                                                ) : (
                                                <span className='clean-badge'>CLEAN</span>
                                                )}
                                            </div>
                                        </td>
                                        <td style={{ width: '10%' }}>
                                            { result.numberId !== null &&
                                            <>
                                                <button className="approve-button-modal"
                                                        onClick={() => handleApprove(result.tfn)}
                                                        style={{ cursor: "pointer" }}>Approve
                                                </button>
                                                <span>/</span>
                                                <button className="deny-button-modal"
                                                        onClick={() => handleDeny(result.tfn)}
                                                        style={{ cursor: "pointer" }}>Deny
                                                </button>
                                            </>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (!loading && results && results.length === 0 && (
                    <div className="check-results-empty">
                        No Data to be displayed!
                    </div>
                    ))}
            </div>
        </div>
    )
};

export default CheckResultsDetails;