import style from './FormInputUserComponent.module.css'

const FormInputUserComponent = ({title, name, type, isRequired, register}) => {
    return (
        <div key={title} className={style.user_input_item}>
            <div className={style.user_input_title}>
                {title}{isRequired && <span className="input-star">&nbsp;*</span>}
            </div>
            <input type={type} {...register(name, { required: isRequired})} className={style.user_add_input}/>
        </div>
    )
}

export default FormInputUserComponent