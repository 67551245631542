import React from 'react';
import 'react-tabs/style/react-tabs.css';

import ChangePassword from '../ChangePassword';

const UserConfigurations = () => {
  return (
    <div className='content-wrapper'>
      <ChangePassword />
    </div>
  );
};

export default UserConfigurations;