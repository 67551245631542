import React, { useEffect, useState } from 'react';
import OverusedTable from '../../components/OverusedTable';
import { useDispatch, useSelector } from 'react-redux';
import {
    clientSearch,
    downloadNumbersForCurrentCompany,
    deleteAllForCurrentCompany,
    resetAddNumbersState,
    deleteMultiplePhones
} from '../../slice/dashboardPhoneSlice';
import { useNavigate } from 'react-router-dom';
import useSorter from '../../service/sorterService';
import { getUserInformation } from '../../slice/UserSlice';
import PopupConfirmation from '../../components/PopupConfirmation';
import FileUploadModal from '../../components/FileUploadModal';
import { AiFillPlusCircle, LiaDownloadSolid, AiFillDelete } from '../../icons';
import toast, { Toaster } from 'react-hot-toast';
import Loader from '../../components/Loader';
import Dropdown from '../../components/Dropdown';

const PureBrandedCallerId = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [page, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(100);
    const user = useSelector(state => state.user.userInfo);
    const data = useSelector(state => state.dashboardPhones.dashboardPhones);
    const totalRegisters = useSelector(state => state.dashboardPhones.totalRegisters);
    const deleteMultipleNumbersSuccess = useSelector(state => state.dashboardPhones.isSuccess);
    const loading = useSelector(state => state.dashboardPhones.loading);
    const { sortingOptions } = useSorter();
    const [popupConfirmation, setPopupConfirmation] = useState(false);
    const [popupConfirmationForSelectedNumbers, setPopupConfirmationForSelectedNumbers] = useState(false);
    const [showUploadFileModal, setShowUploadFileModal] = useState(false);
    const [selectedNumbers, setSelectedNumbers] = useState([]);

    const setPage = value => {
        setCurrentPage(page + value);
    };

    const redirectNumber = number => {
        navigate(`/phone/${number}`);
    };

    const handleSorter = () => {
        fetchClientData();
    }

    const handleDownload = () => {
        dispatch(downloadNumbersForCurrentCompany());
    };

    const handleDeleteAllNumbers = () => {
        setPopupConfirmation(true);
    };

    const deleteAllNumbers = async () => {
        await dispatch(deleteAllForCurrentCompany());
        fetchClientData();
        setPopupConfirmation(false);
    };

    const handleFileUpload = () => {
        dispatch(resetAddNumbersState());
        setShowUploadFileModal(true);
    };

    const onCloseUploadFile = () => {
        fetchClientData();
        setShowUploadFileModal(false);
    }

    const handleSelectAll = () => {
        const allNumbers = data
            .filter(obj => !obj.hasOwnProperty('companyName'))
            .map(obj => obj.id);

        if (!allNumbers) {
            return;
        }

        if (selectedNumbers.length === data.length) {
            setSelectedNumbers([]);
            return;
        }

        setSelectedNumbers(allNumbers)
    };

    const handleDeleteSelectedNumbers = () => {
        if (!deleteMultipleNumbersSuccess) {
            toast.error('There are no selected numbers to delete!', {
                position: 'top-center',
                duration: 3000
            });
            return;
        }

        setPopupConfirmationForSelectedNumbers(true);
    };

    const deleteSelectedNumbers = () => {
        dispatch(deleteMultiplePhones(selectedNumbers)).then(() => {
            fetchClientData();
        });
        setPopupConfirmationForSelectedNumbers(false);
    };

    const setNewPageSize = async (value) => {
        setPageSize(value);
        fetchClientData();
    }

    const fetchClientData = () => {
        dispatch(clientSearch({ branded: true, page: page, pageSize: pageSize, sorterInfo: sortingOptions.currentSorter }));
    }

    useEffect(() => {
        fetchClientData();
    }, [dispatch, user?.id, page, pageSize]);

    useEffect(() => {
        dispatch(getUserInformation());
    }, []);

    return (
        <div className="content-wrapper">
            <Toaster position="top-right" reverseOrder={false} />
            <h2>Pure Caller ID</h2>
            {user && user.admin ? (
            <div className="numbers-buttons">
                <label className="download-numbers" onClick={handleDownload}>
                    Download Numbers
                    <LiaDownloadSolid className='download-icon' />
                </label>
                <label className="add-numbers" onClick={handleFileUpload}>
                    Add Numbers
                    <AiFillPlusCircle className='add-numbers-icon' />
                </label>
                <label className="delete-numbers" onClick={handleDeleteAllNumbers}>
                    Delete All Numbers
                    <AiFillDelete className='delete-numbers-icon' />
                </label>
            </div> ) : <></> }
            {data?.length > 0 && totalRegisters !== 0 && <div className="number-actions-buttons">
                <div className='pagination-selector'>
                    <span className="pagination-text">Results Per Page:</span>
                    <Dropdown
                        options={[10, 25, 50, 100]}
                        selectedOption={pageSize}
                        setSelectedOption={setNewPageSize}
                        useDefaultOption={false}
                    />
                </div>
                {user && user.admin ? (
                <label className="delete-selected-numbers" onClick={handleDeleteSelectedNumbers}>
                    Delete Selected Numbers
                    <AiFillDelete className='delete-numbers-icon' />
                </label> ) : <></> }
            </div>}
            {data?.length > 0 && totalRegisters !== 0 && !loading ? (
                <OverusedTable
                    config={{
                        callerNumber: { title: 'Caller Number' },
                        state: { title: 'State' },
                        atAndT: { title: 'AT&T' },
                        tMobile: { title: 'T Mobile' },
                        verizon: { title: 'Verizon' },
                        ftcStrike: { title: 'FTC Strike' },
                    }}
                    data={data}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    totalRegisters={totalRegisters}
                    redirectNumber={redirectNumber}
                    onSort={handleSorter}
                    handleSelectedNumbers={setSelectedNumbers}
                    selectedNumbers={selectedNumbers}
                    onSelectAll={handleSelectAll}
                />
            ) : loading ? (
                <Loader />
            ) : (
                <div className="empty">No Numbers For Branded Caller Id</div>
            )}
            <div>
                {popupConfirmation &&
                    (
                        (
                            <PopupConfirmation
                                onClosePopup={() => setPopupConfirmation(false)}
                                onDelete={deleteAllNumbers}
                                title={'Are you sure you want to delete all numbers from this client?'}
                            />
                        )
                    )
                }
            </div>
            <div>
                {popupConfirmationForSelectedNumbers &&
                    (
                        (
                            <PopupConfirmation
                                onClosePopup={() => setPopupConfirmationForSelectedNumbers(false)}
                                onDelete={deleteSelectedNumbers}
                                title={'Are you sure you want to delete all selected numbers from this client?'}
                            />
                        )
                    )
                }
            </div>
            <div>
                {showUploadFileModal &&
                    (
                        <FileUploadModal
                            onCloseModal={onCloseUploadFile}
                        />
                    )
                }
            </div>
        </div>
    );
};

export default PureBrandedCallerId;
