import style from './FormButtonUserComponent.module.css'

const FormButtonUserComponent = ({title, type, event}) => {
    return (
        <button onClick={event ? event : null} key={title} type={type} className={style.user_submit}>
            {title}
        </button>
    );
}

export default FormButtonUserComponent