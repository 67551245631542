import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import dashboardSlice from '../slice/dashboardSlice'
import userSlice from '../slice/UserSlice';
import dashboardPhoneSlice from '../slice/dashboardPhoneSlice'
import phoneSlice from '../slice/phoneSlice'
import ftcSlice from '../slice/ftcSlice'
import feedbackSlice from '../slice/feedbackSlice'
import chartSlice from '../slice/chartSlice';
import thunk from 'redux-thunk';
import companiesSlice from '../slice/companiesSlice';
import usaMapDataSlice from '../slice/usaMapDataSlice';
import listChartSlice from '../slice/listChartSlice';
import callLogsSlice from '../slice/callLogsSlice';
import devicesSlice from '../slice/devicesSlice';
import campaignsSlice from '../slice/campaignsSlice';
import campaignCheckSlice from '../slice/campaignCheckSlice';
import clientFeedbackSlice from '../slice/clientFeedbackSlice';
import verizonFeedbackSlice from '../slice/verizonFeedbackSlice';
import notificationsSlice from "../slice/notificationsSlice";
import configurationsSlice from "../slice/systemConfigSlice";
import reportsSlice from "../slice/reportsSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    dashboard: dashboardSlice,
    dashboardPhones: dashboardPhoneSlice,
    phones: phoneSlice,
    ftc: ftcSlice,
    feedback: feedbackSlice,
    chart: chartSlice,
    companies: companiesSlice,
    usaMapData: usaMapDataSlice,
    listChart: listChartSlice,
    callLogs: callLogsSlice,
    devices: devicesSlice,
    campaigns: campaignsSlice,
    campaignCheck: campaignCheckSlice,
    clientFeedback: clientFeedbackSlice,
    verizonFeedback: verizonFeedbackSlice,
    notifications: notificationsSlice,
    configurations: configurationsSlice,
    reports: reportsSlice
  },
  middleware: [thunk]
})

setupListeners(store.dispatch)