import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './CheckResults.css';
import CheckResultsDetails from '../CheckResultsDetails';
import { campaignDetails, spamResult } from '../../slice/campaignCheckSlice';
import { executeByCompanyId, executeById, downloadCsv } from '../../slice/campaignsSlice';
import { campaignsList, deleteCampaigns, revertAll } from '../../slice/campaignsSlice';
import toast, { Toaster } from 'react-hot-toast';
import PopupConfirmation from '../PopupConfirmation';

const CheckResults = () => {
    const dispatch = useDispatch();
    const [selectedDataResult, setSelectedDataResult] = useState(null);
    const [isCampaignCheck, setCampaignCheck] = useState(false);
    const [campaignsResults, setCampaignsResults] = useState([]);
    const campaigns = useSelector(state => state.campaigns.campaignsInfo);
    const isSuccess = useSelector(state => state.campaigns.isSuccess);
    const isError = useSelector(state => state.campaigns.isError);
    const [popupConfirmation, setPopupConfirmation] = useState(false);
    const [campaignToBeDeleted, setCampaignToBeDeleted] = useState(null);
    const [isCampaignExpanded, setIsCampaignExpanded] = useState(false);
    const [isSpotExpanded, setIsSpotExpanded] = useState(false);

    useEffect(() => {
        dispatch(revertAll());
        dispatch(campaignsList());
    }, []);

    useEffect(() => {
        setCampaignsResults(campaigns);
    }, [campaigns]);

    useEffect(() => {
        if (isSuccess) {
            toast.success('Action is being executed', {
                position: 'top-center',
                duration: 3000
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            toast.error('Action fails to execute', {
                position: 'top-center',
                duration: 3000
            });
        }
    }, [isError]);

    const showDetails = (campaign, isCampaignCheck) => {
        dispatch(campaignDetails({ campaignId: campaign.id }));
        dispatch(spamResult({ campaignId: campaign.id }));

        setCampaignCheck(isCampaignCheck);
        setSelectedDataResult(campaign);
    };

    const getCsv = (campaign) => {
        dispatch(downloadCsv(campaign.id));
    }

    const showDeletionConfirmationPopup = (campaign) => {
        setCampaignToBeDeleted(campaign);
        setPopupConfirmation(true);
    }

    const deleteCampaign = async () => {
        await dispatch(deleteCampaigns(campaignToBeDeleted?.id));
        setPopupConfirmation(false);
        setCampaignToBeDeleted(null);
        await dispatch(campaignsList());
    }

    const closeDetails = () => {
        setSelectedDataResult(null);
    };

    const executeCampaign = async (campaign) => {
        if (!campaign.companyId) {
            await dispatch(executeById(campaign.id));
            await dispatch(campaignsList());
            return;
        }

        await dispatch(executeByCompanyId({ campaignId: campaign.id, clientId: campaign.companyId }));
        await dispatch(campaignsList());
    }

    const toggleCampaign = () => setIsCampaignExpanded(!isCampaignExpanded);
    const toggleSpot = () => setIsSpotExpanded(!isSpotExpanded);

    return (
        <div className="check-results">
            <Toaster position="top-right" reverseOrder={false} />
            <div className="result-container">
                <div className="result-section">
                    <div className="section-header" onClick={toggleCampaign}>
                        <span className="check-results-title">Campaign Check Results</span>
                    </div>
                    {isCampaignExpanded && (
                        <table className="results-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {campaignsResults?.filter(campaign => campaign.type === 0).map((campaign, index) => (
                                    <tr key={index}>
                                        <td className="check-results-text">{campaign.name}</td>
                                        <td className="operation-buttons">
                                            <button className="view-details-button" onClick={() => showDetails(campaign, true)}>View</button>
                                            <span className="divider-buttons">/</span>
                                            <button className="view-details-button" onClick={() => showDeletionConfirmationPopup(campaign)}>Delete</button>
                                            <span className="divider-buttons">/</span>
                                            <button className="view-details-button" onClick={() => getCsv(campaign)}>CSV</button>
                                            {!campaign.executed && <span className="divider-buttons">/</span>}
                                            {!campaign.executed && (
                                                <button className={`run-button ${campaign.running ? 'run-button-disabled' : ''}`} onClick={() => executeCampaign(campaign)} disabled={campaign.running}>
                                                    {campaign.running ? 'Running...' : 'Run'}
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className="result-section">
                    <div className="section-header" onClick={toggleSpot}>
                        <span className="check-results-title">Spot Check Results</span>
                    </div>
                    {isSpotExpanded && (
                        <table className="results-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {campaignsResults?.filter(campaign => campaign.type === 1).map((campaign, index) => (
                                    <tr key={index}>
                                        <td className="check-results-text">{campaign.name}</td>
                                        <td className="operation-buttons">
                                            <button className="view-details-button" onClick={() => showDetails(campaign, false)}>View</button>
                                            <span className="divider-buttons">/</span>
                                            <button className="view-details-button" onClick={() => showDeletionConfirmationPopup(campaign)}>Delete</button>
                                            <span className="divider-buttons">/</span>
                                            <button className="view-details-button" onClick={() => getCsv(campaign)}>CSV</button>
                                            {!campaign.executed && <span className="divider-buttons">/</span>}
                                            {!campaign.executed && (
                                                <button className={`run-button ${campaign.running ? 'run-button-disabled' : ''}`} onClick={() => executeCampaign(campaign)} disabled={campaign.running}>
                                                    {campaign.running ? 'Running...' : 'Run'}
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
            {selectedDataResult && (
                <CheckResultsDetails
                    isCampaignCheck={isCampaignCheck}
                    campaign={selectedDataResult}
                    onCloseModal={closeDetails}
                />
            )}
            {popupConfirmation && (
                <PopupConfirmation
                    onClosePopup={() => setPopupConfirmation(false)}
                    onDelete={deleteCampaign}
                    title={`Are you sure you want to delete the ${campaignToBeDeleted?.name} ?`}
                />
            )}
        </div>
    );
};

export default CheckResults;
