import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { findByNumber, findByCurrentUserCompany } from '../../slice/phoneSlice';
import { BsSearch } from '../../icons';
import { FaSpinner } from 'react-icons/fa';
import Loader from '../../components/Loader';
import './Phone.css';
import { getUserInformation } from '../../slice/UserSlice';

const Phone = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const user = useSelector(state => state.user.userInfo);
    const phones = useSelector(state => state.phones.phones);
    const loading = useSelector(state => state.phones.loading);
    const [value, setValue] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [isSearchApplied, setSearchApplied] = useState(false);
    const { pathname } = location;
    const isPhonePath = pathname === '/phone';

    const onChange = e => {
        setValue(e.target.value);
        setDropdownVisible(false);

        if (value === '') {
            setSearchApplied(false);
        }
    };

    const onSearch = number => {
        setValue('');
        navigate(`./${number}`);
        setDropdownVisible(false);
    };

    const handleSearchClick = () => {
        if (searchEmpty())
        {
            return;
        }
        setSearchApplied(true);

        if (user?.admin) {
            dispatch(findByNumber({ tfnNumber: value }));
            return;
        }

        dispatch(findByCurrentUserCompany({ tfnNumber: value }));
    };

    const searchEmpty = () => {
        return value.length === 0;
    };

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    };

    useEffect(() => {
        dispatch(getUserInformation());
    }, []);

    useEffect(() => {
        if (phones.length > 0) {
            setSearchApplied(false);
            (value === '' ) ? setDropdownVisible(false) : setDropdownVisible(true);
        }
    }, [phones]);

    return (
        <div className="content-wrapper">
            <h2>Telephone Number Search</h2>
            <div className="search-wrapper">
                <div className="search">
                    <input
                        type="text"
                        className="search-input"
                        placeholder="Search..."
                        value={value}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                    />
                    {loading ? (
                        <FaSpinner className="search-loading-icon" />
                    ) : (
                        <BsSearch className={`search-icon ${searchEmpty() ? 'search-icon-disabled' : ''}`} onClick={handleSearchClick} />
                    )}
                </div>
                {dropdownVisible && <div className="dropdown-search">
                    {loading ? (
                        <Loader />
                    ) : phones?.length > 0 && (
                        phones.map((phone, key) => (
                            <div className="dropdown-row" key={key} onClick={() => onSearch(phone.tfn)}>
                                {phone.tfn}
                            </div>
                        ))
                    )}
                </div>}
            </div>
            <div className='feedback-messages'>
                {isPhonePath && value === '' && <div className='search-instruction'>Please use the search to find a phone number.</div>}
                {phones?.length === 0 && !loading && isSearchApplied && value !== '' && <div className='no-results-message'>No numbers found that match the search criteria.</div>}
            </div>
            <Outlet />
        </div>
    );
};

export default Phone;
