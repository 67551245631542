import style from './FormUserComponent.module.css'

const FormUserComponent = ({handleSubmit, onSubmit,children}) => {
    return (
        <form onSubmit={handleSubmit(onSubmit)} className={style.user_form}>
            {children}
        </form>
    )
}

export default FormUserComponent