import { createBrowserRouter } from 'react-router-dom';

import App from '../App';
import View from '../pages/ViewWrapper';
import ManageDevices from '../pages/ManageDevices';
import CompanyDetails from '../components/CompanyDetails';
import CompanyEdit from '../components/CompanyEdit';
import CompanyNotBranded from '../components/CompanyNotBranded';
import CompanyBranded from '../components/CompanyBranded';
import PhoneDetails from '../components/PhoneDetails';
import ClientView from '../components/ClientView';
import UserManagement from '../pages/UserManagement';
import ManageNumbers from '../components/ManageNumbers';
import ClientFeedback from '../components/ClientFeedback';
import VerizonFeedback from '../components/VerizonFeedback';
import Reports from '../pages/Reports';

import { AddNew,
    AddNewUserByClient,
    Changes,
    Dasboard,
    Login,
    Phone,
    PureBrandedCallerId,
    PureCallerId,
    Register,
    Upload,
    TestPhoneNumbers,
    ForgotPassword,
    ResetPassword,
    UserConfigurations 
} from '../pages'

export const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '/dashboard',
                element: <Dasboard />
            },
            {
                path: '/user-management',
                element: <UserManagement />
            },
            {
                path: '/add-new',
                element: <AddNew />
            },
            {
                path: '/add-new-user',
                element: <AddNewUserByClient />
            },
            {
                path: '/view',
                element: <View />,
                children: [
                    {
                        path: '/view/:id',
                        element: <CompanyDetails />,
                        children: [
                            {
                                path: '/view/:id/edit',
                                element: <CompanyEdit />
                            },
                            {
                                path: '/view/:id/manage-telephones',
                                element: <ManageNumbers />
                            },
                            {
                                path: '/view/:id/not-branded',
                                element: <CompanyNotBranded />
                            },
                            {
                                path: '/view/:id/branded',
                                element: <CompanyBranded />
                            },
                            {
                                path: '/view/:id/client-view',
                                element: <ClientView />
                            },
                            {
                                path: '/view/:id/client-feedback',
                                element: <ClientFeedback />
                            },
                            {
                                path: '/view/:id/verizon-feedback',
                                element: <VerizonFeedback />
                            }
                        ]
                    }
                ]
            },
            {
                path: '/phone',
                element: <Phone />,
                children: [
                    {
                        path: '/phone/:id',
                        element: <PhoneDetails />
                    }
                ]
            },
            {
                path: '/Upload',
                element: <Upload />
            },
            {
                path: '/changes',
                element: <Changes />
            },
            {
                path: '/pure-branded-caller-id',
                element: <PureBrandedCallerId />
            },
            {
                path: '/pure-caller-id',
                element: <PureCallerId />
            },
            {
                path: '/manage-devices',
                element: <ManageDevices />
            },
            {
                path: '/test-phone-numbers',
                element: <TestPhoneNumbers/>
            },
            {
                path: '/user-configurations',
                element: <UserConfigurations />
            },
            {
                path: '/client-view/:id/client-feedback',
                element: <ClientFeedback />
            },
            {
                path: '/client-view/:id/verizon-feedback',
                element: <VerizonFeedback />
            },
            {
                path: '/reports',
                element: <Reports />
            },
        ]
    },
    {
        path: '/register',
        element: <Register />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword />
    },
    {
        path: '/reset/:userId/:code',
        element: <ResetPassword />
    }
]);
