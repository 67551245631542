import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import CardUserComponent from '../../components/CardUserComponent';
import FormUserComponent from '../../components/FormUserComponent';
import FormInputUserComponent from '../../components/FormInputUserComponent';
import FormButtonUserComponent from '../../components/FormButtonUserComponent';
import FormErrorUserComponent from '../../components/FormErrorUserComponent';

import { forgotPassword } from '../../slice/UserSlice';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [submitMessageError, setSubmitMessageError] = useState("");
    const [gotToLoginPage, setGoToLoginPage] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();
    
    const renderErrorMessage = () => {            
        if (errors.email) {
            return <FormErrorUserComponent error="Email field are required" />
        }
    }

    const onSubmit = async data => {
        try {
            setSubmitMessageError("");

            const result = await dispatch(forgotPassword(data.email));
            if (result.payload !== undefined && result.payload.status === 200) {
                setGoToLoginPage(true);
            } else {
                setSubmitMessageError("Error when trying to send link to reset password");
            }
        } catch (error) {
            setSubmitMessageError(error.message);
        }
    }

    return !gotToLoginPage ? (
        <CardUserComponent title={"Forgot Password"}>
            <FormUserComponent handleSubmit={handleSubmit} onSubmit={onSubmit}>
                <FormInputUserComponent title="Email" isRequired={true} name="email" type="email" register={register} />
                {renderErrorMessage()}
                {submitMessageError && <FormErrorUserComponent error={submitMessageError} />}
                <FormButtonUserComponent title="Send" type="submit" />
            </FormUserComponent>
        </CardUserComponent>
    ) : (
        <CardUserComponent title={"Forgot Password"}>
            <h3>Click on the link you received through email to change your password.</h3>
            <FormButtonUserComponent title="Back to Login" type="button" event={() => navigate("/login")} />
        </CardUserComponent>
    );
}

export default ForgotPassword