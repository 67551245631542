import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getApiEndpoint } from '../utils/apiUtils';

export const resetCampaignCheckState = () => ({
    type: 'campaignCheck/resetState',
});

export const campaignDetails = createAsyncThunk('check/result', async ({ campaignId }) => {
    return axios
        .get(getApiEndpoint('check', `result/${campaignId}`))
        .then(res => res.data);
});

export const spamResult = createAsyncThunk('check/spamResults', async ({ campaignId }) => {
    return axios
        .get(getApiEndpoint('check', `campaign/count/${campaignId}`))
        .then(res => res.data);
});

export const approve = createAsyncThunk('check/approve', async ({ campaignId, tfn }) => {
    return axios
        .post(getApiEndpoint('check', `campaign/approve/${campaignId}/${tfn}`))
        .then(res => res.data);
});

export const deny = createAsyncThunk('check/deny', async ({ campaignId, tfn }) => {
    return axios
        .post(getApiEndpoint('check', `campaign/reprove/${campaignId}/${tfn}`))
        .then(res => res.data);
});

export const approveAll = createAsyncThunk('check/approveAll', async ({ campaignId }) => {
    return axios
        .post(getApiEndpoint('check', `campaign/approve/${campaignId}`))
        .then(res => res.data);
});

export const denyAll = createAsyncThunk('check/denyAll', async ({ campaignId }) => {
    return axios
        .post(getApiEndpoint('check', `campaign/reprove/${campaignId}`))
        .then(res => res.data);
});

const campaignCheckSlice = createSlice({
    name: 'campaignCheck',
    initialState: {
        loading: false,
        results: [],
        spamResult: {},
        error: '',
    },

    extraReducers: builder => {
        builder.addCase(campaignDetails.pending, state => {
            state.loading = true;
        });

        builder.addCase('campaignCheck/resetState', () => {
            return {
                loading: false,
                results: [],
                spamResult: {},
                error: '',
            }
        });

        builder.addCase(campaignDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.results = action.payload;
            state.error = '';
        });

        builder.addCase(campaignDetails.rejected, (state, action) => {
            state.loading = false;
            state.results = [];
            state.error = action.error.message;
        });

        builder.addCase(spamResult.pending, state => {
            state.loading = true;
        });

        builder.addCase(spamResult.fulfilled, (state, action) => {
            state.spamResult = action.payload;
            state.error = '';
        });

        builder.addCase(spamResult.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        builder.addCase(approve.pending, state => {
            state.loading = true;
        });

        builder.addCase(approve.fulfilled, (state) => {
            state.loading = false;
            state.error = '';
        });

        builder.addCase(approve.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        builder.addCase(deny.pending, state => {
            state.loading = true;
        });

        builder.addCase(deny.fulfilled, (state) => {
            state.loading = false;
            state.error = '';
        });

        builder.addCase(deny.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        builder.addCase(approveAll.pending, state => {
            state.loading = true;
        });

        builder.addCase(approveAll.fulfilled, (state) => {
            state.loading = false;
            state.error = '';
        });

        builder.addCase(approveAll.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        builder.addCase(denyAll.pending, state => {
            state.loading = true;
        });

        builder.addCase(denyAll.fulfilled, (state) => {
            state.loading = false;
            state.error = '';
        });

        builder.addCase(denyAll.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

    }
});

export default campaignCheckSlice.reducer;
