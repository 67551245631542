import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ftcUpload } from '../../slice/ftcSlice';
import { fetchDevices } from '../../slice/devicesSlice';
import { AiFillPlusCircle, FaFileCsv } from '../../icons';
import Loader from '../Loader';
import './TestPhones.css';
import { BsSearch } from '../../icons';
import { FaSpinner } from 'react-icons/fa';
import { searchClients } from '../../slice/dashboardSlice';
import { insert, campaignsList, insertByFile, revertAll } from '../../slice/campaignsSlice';
import toast, { Toaster } from 'react-hot-toast';

const TestPhones = () => {
    const dispatch = useDispatch();
    const ftcLoading = useSelector(state => state.ftc.loading);
    const carriers = useSelector(state => state.devices.carriers);
    const loading = useSelector(state => state.devices.loading);
    const cpLoading = useSelector(state => state.campaigns.loading);
    const searchItems = useSelector(state => state.dashboard.data);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [listId, setListId] = useState('100');
    const [telephoneNumbers, setTelephoneNumbers] = useState('');
    const [selectedFileNameCampaignCheck, setSelectedFileNameCampaignCheck] = useState('');
    const [selectedFileNameSpotCheck, setSelectedFileNameSpotCheck] = useState('');
    const [devices, setDevices] = useState(null);
    const [searchVariables, setSearchVariables] = useState('');
    const [value, setValue] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [isSearchApplied, setSearchApplied] = useState(false);
    const [campaignName, setCampaignName] = useState('');
    const [spotName, setSpotName] = useState('');
    const [isClientNameDisabled, setIsClientNameDisabled] = useState(false);
    const [isSpotNumbersDisabled, setSpotNumberDisabled] = useState(false);
    const [campaignCsvNumbers, setCampaignCsvNumbers] = useState('');
    const [spotCsvNumbers, setSpotCsvNumbers] = useState('');
    const createFileSuccess = useSelector(state => state.campaigns.createFileSuccess);
    const createFileError = useSelector(state => state.campaigns.createFileError);

    useEffect(() => {
        dispatch(revertAll());
        dispatch(fetchDevices());
    }, []);

    useEffect(() => {
        setDevices(carriers);
    }, [carriers]);

    const handleListIdChange = (event) => {
        setListId(event.target.value);
    }

    const handleCheckboxChange = (currentDevice) => {
        const isDeviceSelected = selectedDevices.some((device) => device.id === currentDevice.id);

        if (isDeviceSelected) {
            setSelectedDevices(selectedDevices.filter(device => device.id !== currentDevice.id));
        } else {
            setSelectedDevices([...selectedDevices, currentDevice]);
        }
    };

    const handleUpload = (isCampaignCheck, file) => {
        if (!file) {
            return;
        }

        if (isCampaignCheck) {
            setCampaignCsvNumbers(file);
            setSelectedFileNameCampaignCheck(file.name);
            setIsClientNameDisabled(true);
        } else {
            setSpotCsvNumbers(file);
            setSelectedFileNameSpotCheck(file.name);
            setSpotNumberDisabled(true);
        }
    };

    const handleDragOver = e => {
        e.preventDefault();
    };

    const handleFileUploadCampaignCheck = e => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }

        const file = e.target.files[0];
        handleUpload(true, file);
    };

    const handleFileUploadSpotCheck = e => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }

        const file = e.target.files[0];
        handleUpload(false, file);
    };

    const handleDropCampaignCheck = e => {
        e.preventDefault();

        if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) {
            return;
        }

        const file = e.dataTransfer.files[0];
        handleUpload(true, file);
    };

    const handleDropSpotCheck = e => {
        e.preventDefault();

        if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) {
            return;
        }

        const file = e.dataTransfer.files[0];
        handleUpload(false, file);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            const lines = telephoneNumbers.split('\n').length;
            if (lines < 25) {
                setTelephoneNumbers(prevNumbers => prevNumbers + '\n');
            }
        }
    };

    const handleTelephoneNumbersChange = (event) => {
        setTelephoneNumbers(event.target.value);
    };

    const onChange = e => {
        setValue(e.target.value);
        setDropdownVisible(false);

        if (value === '') {
            setSearchApplied(false);
        }
    };

    const onSearch = (searchTerm, id) => {
        setValue(searchTerm);
        setCompanyId(id);
        setDropdownVisible(false);
    };

    const handleSearchClick = async () => {
        if (searchEmpty()) {
            return;
        }
        setSearchApplied(true);
        setDropdownVisible(true);
        dispatch(searchClients({ clientName: value }));
    };

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    };

    const searchEmpty = () => {
        return value.length === 0;
    };

    const onCreateCampaign = async () => {
        if (!campaignName || campaignName === '') {
            alert('Please input the campaign name');
            return;
        }

        if (!validateNumbers()) {
            return;
        }

        if (!companyId && !isClientNameDisabled) {
            alert('Please select the company name or input the number by uploading csv file');
            return;
        }

        const devices = selectedDevices.map((device) => device.id).join(',');
        const data = {
            name: campaignName,
            devices: devices,
            companyId: companyId,
            type: 0,
            listNumber: listId
        };

        if (!isClientNameDisabled) {
            await dispatch(insert(data));
        } else {
            let formData = new FormData();
            formData.append('file', campaignCsvNumbers);
            formData.append('name', campaignName);
            formData.append('devices', devices);
            formData.append('type', 0);
            formData.append('listNumber', listId);
            await dispatch(insertByFile(formData));
        }

        dispatch(campaignsList());
    };

    const onCreateSpotCheck = async () => {
        if (!spotName || spotName === '') {
            alert('Please input the spot check name');
            return;
        }

        if (!validateNumbers()) {
            return;
        }

        if (!telephoneNumbers && !isSpotNumbersDisabled) {
            alert('Please select the company name or input the number by uploading csv file');
            return;
        }

        const devices = selectedDevices.map((device) => device.id).join(',');

        const numbersArray = telephoneNumbers.split('\n');

        const data = {
            name: spotName,
            devices: devices,
            testNumbers: numbersArray,
            type: 1,
            listNumber: '109'
        };
        if (!isSpotNumbersDisabled) {
            await dispatch(insert(data));
        } else {
            let formData = new FormData();
            formData.append('file', spotCsvNumbers);
            formData.append('name', spotName);
            formData.append('devices', devices);
            formData.append('type', 1);
            formData.append('listNumber', '109');
            await dispatch(insertByFile(formData));
        }

        dispatch(campaignsList());
    };

    const updateCampaignName = (event) => {
        setCampaignName(event.target.value);
    };

    const updateSpotName = (event) => {
        setSpotName(event.target.value);
    };

    const validateNumbers = () => {
        const attNumbers = selectedDevices.filter(device => device.carrier === 0);
        const verizonNumbers = selectedDevices.filter(device => device.carrier === 1);
        const tMobileNumbers = selectedDevices.filter(device => device.carrier === 2);

        if (attNumbers.length === 0 && verizonNumbers.length === 0 && tMobileNumbers.length === 0) {
            alert('Please select at least one device from each carrier.');
            return false;
        }

        if (attNumbers.length !== verizonNumbers.length || attNumbers.length !== tMobileNumbers.length || verizonNumbers.length !== tMobileNumbers.length) {
            alert('Please select the same amout of devices in all three carriers');
            return false;
        }

        return true;
    };

    useEffect(() => {
        if (searchItems?.length > 0) {
            setSearchVariables(searchItems);
            (value === '') ? setDropdownVisible(false) : setDropdownVisible(true);
        }
    }, [searchItems]);

    useEffect(() => {
        if (createFileSuccess) {
            toast.success('Campaign has been created !', {
                position: 'top-center',
                duration: 3000
            });
        }
    }, [createFileSuccess]);

    useEffect(() => {
        if (createFileError) {
            toast.error('Failed to create the campaign', {
                position: 'top-center',
                duration: 3000
            });
        }
    }, [createFileError]);

    return (
        <div className="test-phones">
            <Toaster position="top-right" reverseOrder={false} />
            {!loading && devices?.length > 0 && (
                <>
                    <div className="phone-list">
                        {devices?.map(carrier => (
                            <div key={carrier.carrierName} className="carrier">
                                <span className="company-name">{carrier.carrierName}</span>
                                <div className="device-list">
                                    {carrier.devices.map(device => (
                                        <div key={device.deviceName} className="device">
                                            <label className="checkbox-container">
                                                <input
                                                    id={device.deviceName}
                                                    className={`custom-checkbox ${selectedDevices.some(selectedDevice => selectedDevice.id === device.id) ? 'checked' : ''}`}
                                                    type="checkbox"
                                                    checked={selectedDevices.some(selectedDevice => selectedDevice.id === device.id)}
                                                    onChange={() => handleCheckboxChange(device)}
                                                />
                                                <span className="checkmark"></span>
                                                <span className="phone-info">
                                                    {device.deviceName} - {device.number}
                                                </span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="action-section">
                        <div className="campaign-check">
                            <span className="check-title">Campaign Check</span>
                            <div className="check-item">
                                <span className="check-text">Campaign Check Name</span>
                                <input type="text" className="input-fields" id="campaignInput" onChange={updateCampaignName} />
                            </div>
                            <div className="input-wrapper">
                                <span className="check-text">Select your List</span>
                                <select className="add-input" value={listId} onChange={handleListIdChange}>
                                    <option value="">Select a campaign list</option>
                                    <option value="100">Campaign-1</option>
                                    <option value="101">Campaign-2</option>
                                    <option value="102">Campaign-3</option>
                                    <option value="103">Campaign-4</option>
                                    <option value="104">Campaign-5</option>
                                    <option value="105">Campaign-6</option>
                                    <option value="106">Campaign-7</option>
                                    <option value="107">Campaign-8</option>
                                    <option value="108">Campaign-9</option>
                                </select>
                            </div>
                            <div className="search-wrapper">
                                <span className="check-text">Search Client</span>
                                <div className="search">
                                    <input
                                        type="text"
                                        className="search-input"
                                        placeholder="Search..."
                                        value={value}
                                        onChange={onChange}
                                        onKeyDown={onKeyDown}
                                        disabled={isClientNameDisabled}
                                    />
                                    {loading ? (
                                        <FaSpinner className="search-loading-icon" />
                                    ) : (
                                        <BsSearch className={`search-icon ${searchEmpty() ? 'search-icon-disabled' : ''}`} onClick={handleSearchClick} />
                                    )}
                                </div>
                                {dropdownVisible && (
                                    <div className="dropdown-search">
                                        {searchVariables?.length > 0 &&
                                            searchVariables.map(value => (
                                                <div className="dropdown-row" key={value._id} onClick={() => onSearch(value.companyName, value.id)}>
                                                    {value.companyName}
                                                </div>
                                            ))}
                                    </div>
                                )}
                            </div>
                            <span className="check-text additional-label">Or</span>
                            <div className="bottom-section">
                                <div className="upload-content" onDragOver={handleDragOver} onDrop={handleDropCampaignCheck}>
                                    <p className="upload-title test-numbers-title">Select the CSV file</p>
                                    <label className="custom test-numbers">
                                        {ftcLoading ? (
                                            <Loader />
                                        ) : (
                                            <>
                                                {selectedFileNameCampaignCheck !== '' ? <FaFileCsv /> : <AiFillPlusCircle style={{ width: '40px', height: '40px' }} />}
                                                <input type="file" accept=".csv" onChange={handleFileUploadCampaignCheck} />
                                                <div className="custom-text">{selectedFileNameCampaignCheck !== '' ? selectedFileNameCampaignCheck : 'Custom File'}</div>
                                            </>
                                        )}
                                    </label>
                                </div>
                                {cpLoading ? (
                                    <Loader />
                                ) : (
                                    <button className="create-button" onClick={onCreateCampaign}>
                                        Create Campaign
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="spot-check">
                            <span className="check-title">Spot Check</span>
                            <div className="check-item">
                                <span className="check-text">Spot Check Name</span>
                                <input type="text" className="input-fields" id="spotInput" onChange={updateSpotName} />
                            </div>
                            <div className="check-item">
                                <span className="check-text">Enter up to 25 Telephone Numbers</span>
                                <textarea
                                    className="input-fields"
                                    id="spotInput"
                                    value={telephoneNumbers}
                                    rows="3"
                                    onKeyDown={handleKeyDown}
                                    onChange={handleTelephoneNumbersChange}
                                    disabled={isSpotNumbersDisabled}
                                />
                            </div>
                            <span className="check-text additional-label">Or</span>
                            <div className="bottom-section">
                                <div className="upload-content" onDragOver={handleDragOver} onDrop={handleDropSpotCheck}>
                                    <p className="upload-title test-numbers-title">Select the CSV file</p>
                                    <label className="custom test-numbers">
                                        {ftcLoading ? (
                                            <Loader />
                                        ) : (
                                            <>
                                                {selectedFileNameSpotCheck !== '' ? <FaFileCsv /> : <AiFillPlusCircle style={{ width: '40px', height: '40px' }} />}
                                                <input type="file" accept=".csv" onChange={handleFileUploadSpotCheck} />
                                                <div className="custom-text">{selectedFileNameSpotCheck !== '' ? selectedFileNameSpotCheck : 'Custom File'}</div>
                                            </>
                                        )}
                                    </label>
                                </div>
                                {cpLoading ? (
                                    <Loader />
                                ) : (
                                    <button className="create-button" onClick={onCreateSpotCheck}>
                                        Create Spot Check
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
            {!loading && devices?.length === 0 && <div className="no-data-message">No devices available</div>}
            {loading && <Loader />}
        </div>

    );
};

export default TestPhones;