import React, { useState, useEffect } from 'react';
import './Upload.css';

import { useDispatch, useSelector } from 'react-redux';
import { ftcUpload, resetFtcUploadInfo } from '../../slice/ftcSlice';
import { uploadClientFeedback, resetInfo } from '../../slice/clientFeedbackSlice';
import { FaExclamationCircle, AiFillPlusCircle, FaFileCsv } from '../../icons';
import Loader from '../../components/Loader';
import { useNavigate } from 'react-router-dom';

const Upload = () => {
    const navigate = useNavigate();
    const [selectedFileName, setSelectedFileName] = useState('');
    const dispatch = useDispatch();
    const ftcResponse = useSelector(state => state.ftc.ftc);
    const ftcResponseError = useSelector(state => state.ftc.isError);
    const ftcLoading = useSelector(state => state.ftc.loading);
    const clientFeedbackIsUploadError = useSelector(state => state.clientFeedback.isUploadError);
    const uploadResult = useSelector(state => state.clientFeedback.uploadResult);
    const clientFeedbackLoading = useSelector(state => state.clientFeedback.loading);

    const [expandedCompanies, setExpandedCompanies] = useState([]);

    const toggleCompany = companyId => {
        if (expandedCompanies.includes(companyId)) {
            setExpandedCompanies(expandedCompanies.filter(id => id !== companyId));
        } else {
            setExpandedCompanies([...expandedCompanies, companyId]);
        }
    };

    const handleUpload = (file) => {
        if (!file) {
            return;
        }

        let formData = new FormData();
        formData.append('file', file);
        dispatch(ftcUpload({ file: formData }));
    };

    const handleFileUpload = e => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }

        const file = e.target.files[0];
        handleUpload(file);
    };

    const handleDragOver = e => {
        e.preventDefault();
    };

    const handleDrop = e => {
        e.preventDefault();

        if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) {
            return;
        }

        const file = e.dataTransfer.files[0];
        handleUpload(file);
    };

    const handleUploadForClientFeedback = (file) => {
        if (!file) {
            return;
        }

        let formData = new FormData();
        formData.append('file', file);
        dispatch(uploadClientFeedback({ file: formData }));
    };

    const handleFileUploadForClientFeedback = e => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }

        const file = e.target.files[0];
        handleUploadForClientFeedback(file);
    };

    const handleDragOverForClientFeedback = e => {
        e.preventDefault();
    };

    const handleDropForClientFeedback = e => {
        e.preventDefault();

        if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) {
            return;
        }

        const file = e.dataTransfer.files[0];
        handleUpload(file);
    };

    useEffect(() => {
        resetFtcUploadInfo();
        resetInfo();
    }, [navigate]);

    return (
        <div className="content-wrapper">
            <div className='upload-wrapper'>
                <div className='upload-content'
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    <h2>Process FTC Upload</h2>
                    <p className="upload-title">Select the CSV file</p>
                    <label className="custom">
                        {ftcLoading ? (
                            <Loader />
                        ) : (
                            <>
                                {selectedFileName !== '' ? <FaFileCsv /> : <AiFillPlusCircle style={{ width: '40px', height: '40px' }} />}
                                <input type="file" accept=".csv" onChange={handleFileUpload} />
                                <div className='custom-text'>{selectedFileName !== '' ? selectedFileName : 'Custom File'}</div>
                            </>
                        )}
                    </label>
                </div>
                <div className='upload-content'
                    onDragOver={handleDragOverForClientFeedback}
                    onDrop={handleDropForClientFeedback}
                >
                    <h2>Process Client Feedback</h2>
                    <p className="upload-title">Select the CSV file</p>
                    <label className="custom">
                        {clientFeedbackLoading ? (
                            <Loader />
                        ) : (
                            <>
                                {selectedFileName !== '' ? <FaFileCsv /> : <AiFillPlusCircle style={{ width: '40px', height: '40px' }} />}
                                <input type="file" accept=".csv" onChange={handleFileUploadForClientFeedback} />
                                <div className='custom-text'>{selectedFileName !== '' ? selectedFileName : 'Custom File'}</div>
                            </>
                        )}
                    </label>
                </div>
                <div className='upload-content'>
                    <h2>Process TRNM Upload</h2>
                    <p className="upload-title">Coming soon !</p>
                    <label className="custom inactive">
                        <>
                            <AiFillPlusCircle style={{ width: '40px', height: '40px' }} />
                            <input disabled type="file" accept=".csv" onChange={handleFileUpload} />
                            <div className='custom-text'>{'Custom File'}</div>
                        </>
                    </label>
                </div>
            </div>
            {!clientFeedbackIsUploadError && uploadResult?.total > 0 ? (
                <div className="ftc-results">
                    <h4>Client Feedback Processing Results:</h4>
                    <div className="ftc-result-row">
                        <p className="ftc-result-title">Total numbers processed: </p>
                        <b className="ftc-result-value">{uploadResult.total}</b>
                    </div>
                    <div className="ftc-result-companies">
                        <p className="ftc-result-item ftc-result-title">List of clients feedback by company: </p>
                        {uploadResult.companies.map(company => (
                            <div key={company.id} className="ftc-result-item">
                                <div onClick={() => toggleCompany(company.id)}>{company.companyName}</div>
                                {expandedCompanies.includes(company.id) && (
                                    <ul>
                                        {company.flaggedPhones?.map(number => (
                                            <li key={number} className='ftc-result-number'>{number}</li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <>
                    {clientFeedbackIsUploadError && <div className="ftc-error-message">
                        <span className="ftc-error-icon"><FaExclamationCircle /></span>
                        <p>We encountered an error loading the file. Please ensure that the Client feedback flags for your clients are properly configured.</p>
                    </div>}
                </>
            )}
            {!ftcResponseError && ftcResponse.hasOwnProperty('total') ? (
                <div className="ftc-results">
                    <h4>Processing Results:</h4>
                    <div className="ftc-result-row">
                        <p className="ftc-result-title">Total numbers processed: </p>
                        <b className="ftc-result-value">{ftcResponse.total}</b>
                    </div>
                    <div className="ftc-result-row">
                        <p className="ftc-result-title">Number of numbers flagged with FTC: </p>
                        <b className="ftc-result-value">{ftcResponse.ftcFlagged}</b>
                    </div>
                    <div className="ftc-result-companies">
                        <p className="ftc-result-item ftc-result-title">List of clients with FTC flags: </p>
                        {ftcResponse?.companies?.length > 0 ? (
                            ftcResponse.companies.map(item => (
                                <div key={item.id} className="ftc-result-item">
                                    <div onClick={() => toggleCompany(item.id)}>{item.companyName}</div>
                                    {expandedCompanies.includes(item.id) && (
                                        <ul>
                                            {item?.flaggedPhones?.map(number => (
                                                <li key={number.id} className='ftc-result-number'>{number.tfn}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            ))
                        ) : (
                            <>Your clients don't have FTC flags</>
                        )}
                    </div>
                </div>
            ) : (
                <div>
                    {ftcResponseError && (
                        <div className="ftc-error-message">
                            <span className="ftc-error-icon"><FaExclamationCircle /></span>
                            <p>We encountered an error loading the file. Please ensure that the FTC flags for your clients are properly configured.</p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Upload;
