import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './TestPhoneNumbers.css';
import CheckResults from '../../components/CheckResults';
import TestPhones from '../../components/TestPhones';
import { getUserInformation } from '../../slice/UserSlice';
import Loader from "../../components/Loader";

const TestPhoneNumbers = () => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.campaigns.loading);

  useEffect(() => {
    dispatch(getUserInformation());
  }, []);

  return (
    <div className="test-phone-number">
      <span className="test-phone-title">TFN Check Dashboard</span>
      <div className="rectangle-container">
        <div className="rectangle">
          <span className="test-phone-section">Test Phone Numbers</span>
          <TestPhones />
        </div>
      </div>
      <div className="rectangle-container">
        <div className="rectangle">
          <span className="test-phone-section">Check Results</span>
          {loading && <Loader />}
          <CheckResults />
        </div>
      </div>
    </div>
  );
};

export default TestPhoneNumbers;
