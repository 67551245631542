import {createSlice, createAsyncThunk, createAction} from '@reduxjs/toolkit';
import axios from 'axios';
import { getApiEndpoint } from '../utils/apiUtils';

export const campaignsList = createAsyncThunk('campaigns/list', async () => {
    return axios
        .get(getApiEndpoint('campaigns', 'list'))
        .then(res => res.data);
});

export const insert = createAsyncThunk('campaigns/insert', async (data) => {
    return axios
        .post(getApiEndpoint('campaigns', 'insert'), {
            name: data.name,
            devices: data.devices,
            companyId: data.companyId,
            testNumbers: data.testNumbers,
            listNumber: data.listNumber,
            type: data.type
        })
        .then(res => res.data);
});

export const insertByFile = createAsyncThunk('campaigns/insertByFile', async (file) => {
    return axios
        .post(getApiEndpoint('campaigns', 'insertByFile'), file)
        .then(res => res.data);
});

export const executeByCompanyId = createAsyncThunk('campaigns/executeByCompanyId', async ({ campaignId, clientId }) => {
    return axios
        .get(getApiEndpoint('campaigns', `execute/${campaignId}/${clientId}`))
        .then(res => res.data);
});

export const executeById = createAsyncThunk('campaigns/executeById', async (id) => {
    return axios
        .get(getApiEndpoint('campaigns', `execute/${id}`))
        .then(res => res.data);
});

export const deleteCampaigns = createAsyncThunk('campaigns/deleteCampaigns', async (id) => {
    return axios
        .delete(getApiEndpoint('campaigns', `delete/${id}`))
        .then(res => res.data);
});

export const downloadCsv = createAsyncThunk('campaigns/downloadCsv', async (id) => {
    return axios
        .get(getApiEndpoint('check', `result/csv/${id}`))
        .then(response => {
            const csvData = response.data
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'data.csv';
            link.click();
            URL.revokeObjectURL(url);
        });
});

export const revertAll = createAction('REVERT_ALL');

const campaignsSlice = createSlice({
    name: 'campaigns',
    initialState: {
        loading: false,
        campaignsInfo: [],
        error: '',
        isSuccess: false,
        isError: false,
        createFileSuccess: false,
        createFileError: false,
        deleteFileSuccess: false,
        deleteFileError: false
    },

    extraReducers: builder => {
        builder.addCase(revertAll, (state) => {
            state.createFileSuccess = false;
            state.createFileError = false;
            state.deleteFileSuccess = false;
            state.deleteFileError = false;
            state.isSuccess = false;
            state.isError = false;
        });

        builder.addCase(campaignsList.pending, state => {
            state.loading = true;
        });

        builder.addCase(campaignsList.fulfilled, (state, action) => {
            state.loading = false;
            state.campaignsInfo = action.payload;
            state.error = '';
        });

        builder.addCase(campaignsList.rejected, (state, action) => {
            state.loading = false;
            state.campaignsInfo = [];
            state.error = action.error.message;
        });

        builder.addCase(insert.pending, state => {
            state.loading = true;
        });

        builder.addCase(insert.fulfilled, (state) => {
            state.loading = false;
            state.isSuccess = true;
            state.isError = false;
        });

        builder.addCase(insert.rejected, (state) => {
            state.loading = false;
            state.isError = true;
        });

        builder.addCase(executeByCompanyId.pending, state => {
            state.loading = true;
        });

        builder.addCase(executeByCompanyId.fulfilled, (state) => {
            state.loading = false;
            state.createFileSuccess = true;
            state.createFileError = false;
        });

        builder.addCase(executeByCompanyId.rejected, (state) => {
            state.loading = false;
            state.createFileSuccess = false;
            state.createFileError = true;
        });


        builder.addCase(executeById.pending, state => {
            state.loading = true;
        });

        builder.addCase(executeById.fulfilled, (state) => {
            state.loading = false;
            state.isSuccess = true;
            state.isError = false;
        });

        builder.addCase(executeById.rejected, (state) => {
            state.loading = false;
            state.isError = true;
        });

        builder.addCase(insertByFile.pending, state => {
            state.loading = true;
        });

        builder.addCase(insertByFile.fulfilled, (state) => {
            state.loading = false;
            state.createFileSuccess = true;
            state.createFileError = false;
        });

        builder.addCase(insertByFile.rejected, (state) => {
            state.loading = false;
            state.createFileSuccess = false;
            state.createFileError = true;
        });

        builder.addCase(deleteCampaigns.pending, state => {
            state.loading = true;
        });

        builder.addCase(deleteCampaigns.fulfilled, (state) => {
            state.loading = false;
            state.deleteFileSuccess = true;
            state.deleteFileError = false;
        });

        builder.addCase(deleteCampaigns.rejected, (state) => {
            state.loading = false;
            state.deleteFileSuccess = false;
            state.deleteFileError = true;
        });
    }
});

export default campaignsSlice.reducer;