import style from './CardUserComponent.module.css'
import './CardUser.css';
import logo from '../../logo.svg';

const CardUserComponent = ({title, showLogo = true, children}) => {
    return (
        <div className={style.user_form_wrapper}>
             {showLogo && <img src={logo} alt="logo" />}
             <span className="user-configuration-title">{title}</span>
             {children}
        </div>
    )
}

export default CardUserComponent;