import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { getUserInformation, changePassword } from '../../slice/UserSlice';

import CardUserComponent from '../../components/CardUserComponent';
import FormUserComponent from '../../components/FormUserComponent';
import FormInputUserComponent from '../../components/FormInputUserComponent';
import FormButtonUserComponent from '../../components/FormButtonUserComponent';
import FormErrorUserComponent from '../../components/FormErrorUserComponent';

const validatePassword = (field, password) => {
    if (!password) {
        return "";
    }

    if (password.length < 5 || password.length > 10) {
        return `${field} must be between 5 and 10 characters long`;
    }

    if (!/[A-Z]/.test(password)) {
        return `${field} must contain at least one uppercase letter`;
    }

    if (!/[a-z]/.test(password)) {
        return `${field} must contain at least one lowercase letter`;
    }

    if (!/\d/.test(password)) {
        return `${field} must contain at least one number`;
    }

    if (!/[!@#$%^&*]/.test(password)) {
        return `${field} must contain at least one special character (!@#$%^&*)`;
    }

    return "";
};

const ChangePassword = () => {
    const user = useSelector(state => state.user.userInfo);
    const [showMessagePasswordChange, setShowMessagePasswordChange] = useState(false);
    const [submitMessageError, setSubmitMessageError] = useState("");

    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm();

    const password = watch('password');
    const confirmPassword = watch('confirmpassword');

    useEffect(() => {
        dispatch(getUserInformation());
    }, []);

    const renderErrorMessage = () => {
        var validateErrorPassword = validatePassword("Password", password);
        var validateErrorConfirmPassword = validatePassword("Confirm Password", confirmPassword);

        if (validateErrorPassword) return <FormErrorUserComponent error={validateErrorPassword} />

        if (validateErrorConfirmPassword) return <FormErrorUserComponent error={validateErrorConfirmPassword} />

        if (errors.password) {
            return <FormErrorUserComponent error="New password field are required" />
        }

        if (errors.confirmpassword) {
            return <FormErrorUserComponent error="Confirm password field are required" />
        }

        if (password !== confirmPassword) {
            return <FormErrorUserComponent error="Passwords do not match" />;
        }
    }

    const onSubmit = async data => {
        try {
            setSubmitMessageError("");

            if (password !== confirmPassword) {
                return;
            };

            const result = await dispatch(changePassword({ 
                email: user.email, 
                password: data.password, 
                firstName: user.firstName,
                lastName: user.lastName
             }));

            if (result.payload !== undefined && result.payload.status === 200) {
                setShowMessagePasswordChange(true);
            } else {
                setSubmitMessageError("Error when trying to reset password");
            }  
        } catch (error) {
            setSubmitMessageError(error.message);
        }
    }

    return !showMessagePasswordChange ? (
        <CardUserComponent title={"Change Password"} showLogo={false}>
            <FormUserComponent handleSubmit={handleSubmit} onSubmit={onSubmit}>
                <FormInputUserComponent title="New Password" isRequired={true} name="password" type="password" register={register} />
                <FormInputUserComponent title="Confirm Password" isRequired={true} name="confirmpassword" type="password" register={register} />
                {renderErrorMessage()}
                {submitMessageError && <FormErrorUserComponent error={submitMessageError} />}
                <FormButtonUserComponent title="Send" type="submit" />
            </FormUserComponent>
        </CardUserComponent>
    ) : (
        <>
            <CardUserComponent title={"Password Change"} showLogo={false} />
        </>
    );
};

export default ChangePassword;