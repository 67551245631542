import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'; 

import CardUserComponent from '../../components/CardUserComponent';
import FormUserComponent from '../../components/FormUserComponent';
import FormInputUserComponent from '../../components/FormInputUserComponent';
import FormButtonUserComponent from '../../components/FormButtonUserComponent';
import FormErrorUserComponent from '../../components/FormErrorUserComponent';

import { resetPassword, confirmPasswordKey } from '../../slice/UserSlice';

const validatePassword = (field, password) => {
    if (!password) {
        return "";
    }

    if (password.length < 5 || password.length > 10) {
        return `${field} must be between 5 and 10 characters long`;
    }

    if (!/[A-Z]/.test(password)) {
        return `${field} must contain at least one uppercase letter`;
    }

    if (!/[a-z]/.test(password)) {
        return `${field} must contain at least one lowercase letter`;
    }

    if (!/\d/.test(password)) {
        return `${field} must contain at least one number`;
    }

    if (!/[!@#$%^&*]/.test(password)) {
        return `${field} must contain at least one special character (!@#$%^&*)`;
    }

    return "";
};

const ResetPassword = () => {
    const { userId, code } = useParams();
    const [showPage, setShowPage] = useState(false);
    const [submitMessageError, setSubmitMessageError] = useState("");

    const location = useLocation();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm();

    const password = watch('password');
    const confirmPassword = watch('confirmpassword');

    const renderErrorMessage = () => {
        var validateErrorPassword = validatePassword("Password", password);
        var validateErrorConfirmPassword = validatePassword("Confirm Password", confirmPassword);

        if (validateErrorPassword) return <FormErrorUserComponent error={validateErrorPassword} />

        if (validateErrorConfirmPassword) return <FormErrorUserComponent error={validateErrorConfirmPassword} />

        if (errors.password) {
            return <FormErrorUserComponent error="New password field are required" />
        }

        if (errors.confirmpassword) {
            return <FormErrorUserComponent error="Confirm password field are required" />
        }

        if (password !== confirmPassword) {
            return <FormErrorUserComponent error="Passwords do not match" />;
        }
    }

    const onSubmit = async data => {
        try {
            setSubmitMessageError("");

            if (password !== confirmPassword) {
                return;
            }

            const result = await dispatch(resetPassword({ password: data.password, userId: userId, code: code }));
            if (result.payload !== undefined && result.payload.status === 200) {
                navigate('/login');
            } else {
                setSubmitMessageError("Error when trying to reset password");
            }
        } catch (error) {
            setSubmitMessageError(error.message);
        }
    }

    useEffect(() => {
        if (!userId || !code) {
            setShowPage(false);
            return;
        }

        const validateKeyCode = async () => {
            const result = await dispatch(confirmPasswordKey(userId, code));
            setShowPage(result.payload);
        };

        validateKeyCode();
    }, [dispatch, userId, code]);

    return showPage ? (
        <CardUserComponent title={"Reset Password"}>
            <FormUserComponent handleSubmit={handleSubmit} onSubmit={onSubmit}>
                <FormInputUserComponent title="New Password" isRequired={true} name="password" type="password" register={register} />
                <FormInputUserComponent title="Confirm Password" isRequired={true} name="confirmpassword" type="password" register={register} />
                {renderErrorMessage()}
                {submitMessageError && <FormErrorUserComponent error={submitMessageError} />}
                <FormButtonUserComponent title="Send" type="submit" />
            </FormUserComponent>
        </CardUserComponent>
    ) : (
        <CardUserComponent title={"Page not found"}>
            <FormButtonUserComponent title="Back to Home" type="button" event={() => navigate("/")} />
        </CardUserComponent>
    )
}

export default ResetPassword