import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMobileApiEndpoint } from '../utils/apiUtils';
import axios from 'axios';

export const getCallLogs = createAsyncThunk('logs/getCallLogs', async () => {
    return axios
        .get(getMobileApiEndpoint('logs', 'list'))
        .then(res => res.data);
});

export const deleteCallLog = createAsyncThunk('logs/deleteCallLog', async ({ id }) => {
    return axios
        .delete(getMobileApiEndpoint('logs', `delete/${id}`))
        .then(res => res.data);
});

export const exportCsv = createAsyncThunk('logs/exportCsv', async ({ id }) => {
    return axios
        .get(getMobileApiEndpoint('logs', `csv/${id}`))
        .then(response => {
            const csvData = response.data
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'call_logs.csv';
            link.click();
            URL.revokeObjectURL(url);
        });
});

const callLogsSlice = createSlice({
    name: 'callLogs',
    initialState: {
        loading: false,
        phones: [],
        error: '',
    },
    extraReducers: builder => {
        builder.addCase(getCallLogs.pending, state => {
            state.loading = true;
        });

        builder.addCase(getCallLogs.fulfilled, (state, action) => {
            state.loading = false;
            state.phones = action.payload;
            state.error = '';
        });

        builder.addCase(getCallLogs.rejected, (state, action) => {
            state.loading = false;
            state.phones = [];
            state.error = action.error.message;
        });


        builder.addCase(deleteCallLog.pending, state => {
            state.loading = true;
        });

        builder.addCase(deleteCallLog.fulfilled, (state) => {
            state.loading = false;
            state.error = '';
        });

        builder.addCase(deleteCallLog.rejected, (state) => {
            state.loading = false;
        });

        builder.addCase(exportCsv.pending, state => {
            state.loading = true;
        });

        builder.addCase(exportCsv.fulfilled, (state) => {
            state.loading = false;
            state.error = '';
        });

        builder.addCase(exportCsv.rejected, (state) => {
            state.loading = false;
        });
    }
});

export default callLogsSlice.reducer;
